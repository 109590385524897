import React from "react"

const AnchorLink = ({ children, to, offset=70 }) => {
  return <a href={`${to}`} onClick={() => {
    smoothScroll(document.getElementById(to.replace('#', '')), offset)
  }}>{children}</a>
}

const smoothScroll = (target, offset) => {
  let toY;
  let nowY = window.pageYOffset;      //現在のスクロール値
  const divisor = 6;                  //近づく割合（数値が大きいほどゆっくり近く）
  const range = (divisor / 2) + 1;    //どこまで近づけば処理を終了するか(無限ループにならないように divisor から算出)

  //ターゲットの座標
  const targetRect = target.getBoundingClientRect();   //ターゲットの座標取得
  const targetY = targetRect.top + nowY - offset;      //現在のスクロール値 & ヘッダーの高さを踏まえた座標

  //スクロール終了まで繰り返す処理
  const scrollFunc = () => {
    toY = nowY + Math.round((targetY - nowY) / divisor);  //次に移動する場所（近く割合は除数による。）
    window.scrollTo(0, toY);                              //スクロールさせる
    nowY = toY;                                           //nowY更新

    if (document.body.clientHeight - window.innerHeight < toY) {
      
      //最下部にスクロールしても対象まで届かない場合は下限までスクロールして強制終了
      window.scrollTo(0, document.body.clientHeight);
      return;
      
    }
    if (toY >= targetY + range || toY <= targetY - range) {
      
      //+-rangeの範囲内へ近くまで繰り返す
      window.setTimeout(scrollFunc, 5);
      
    } else {
      
      //+-range の範囲内にくれば正確な値へ移動して終了。
      window.scrollTo(0, targetY);
      
    }
  }
  scrollFunc()
}

export default AnchorLink