import React from "react"
import { Link, withPrefix } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { SchoolAreaBlock } from "../components/category-block"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import { formatDate } from "../components/data-control"
import InterviewTag from "../components/interview-tag"
import AnchorLink from '../components/anchor-link'

export default function SchoolDataPage({ data, pageContext }) {
  const { areaSortPrefectures } = pageContext
  const { area } = useSiteMetadata()
  const category = 'school'
  const schoolData = data.school
  const isSimple = schoolData.order > 10000 // SchoolDataAddOrder と同じにする
  const mapLinkUrl = mapLinkUrlCreate({map_tag: schoolData.map_tag})
  const existsWork = schoolData.work ? true : false
  const appealBlock = isSimple ? false : <Appeal data={schoolData} />
  const interviewLength = data.interview.edges.length

  return (
    <Layout slug={schoolData.slug} category={category} area={schoolData.area} prefecture={schoolData.prefecture} showSchoolArea={false} showJob={false} showCharm={false} {...data.meta.nodes[0]} metaReplace={{
      school_name: schoolData.school_name,
      prefecture_name: schoolData.prefecture_name,
      municipalities: schoolData.municipalities,
      school_type: schoolData.school_type
    }}>
      {!isSimple && (
        <div className="c-section-kv">
          <img src={withPrefix(`/img/school/${schoolData.school_id}/header.jpg`)} style={{width:"100%", height:"100%"}} alt="学校情報" />
        </div>
      )}
      <div className="c-page-title c-page-title-school-detail">
        <h1>{schoolData.school_name}</h1>
        <span>{schoolData.school_type} / {schoolData.prefecture_name}{schoolData.municipalities}{schoolData.corporate_name ? ` / ${schoolData.corporate_name}` : ""}</span>
      </div>

      <div className="p-nav-contents">
        <nav>
          <Menu isSimple={isSimple} schoolName={schoolData.school_name} existsWork={existsWork} existsAppeal={appealBlock === false ? false : true} interviewLength={interviewLength} />
        </nav>
      </div>
      {!isSimple && (
      <section className="p-section" id="section01">
        <div className="cols2">
          <div className="col">
            <img src={withPrefix(`/img/school/${schoolData.school_id}/info.jpg`)} style={{width:"100%", height:"100%"}} alt={schoolData.school_name} />
          </div>
          <section className="col">
            <h2><mark>{schoolData.top_heading}</mark></h2>
            <p dangerouslySetInnerHTML={{ __html: nl2br(schoolData.top_text) }} />
          </section>
        </div>
      </section>
      )}
      <section className="p-section" id="section02">
        <div className="c-section-title">
          <span>{schoolData.school_name}の</span>
          <h2>募集内容・学費</h2>
        </div>

        <Class
          name={`${schoolData.department}${(schoolData.night_capacity > 0 ? (schoolData.daytime ? schoolData.daytime : "(昼間部)") : "")}`}
          capacity={schoolData.daytime_capacity}
          target={schoolData.daytime_target}
          term={schoolData.daytime_term}
          expenses={schoolData.daytime_expenses}
          lesson_time={schoolData.daytime_lesson_time}
          summary={schoolData.daytime_summary}
        />

        <Class
          name={`${schoolData.department}${(schoolData.night ? schoolData.night : "(夜間部)")}`}
          capacity={schoolData.night_capacity}
          target={schoolData.night_target}
          term={schoolData.night_term}
          expenses={schoolData.night_expenses}
          lesson_time={schoolData.night_lesson_time}
          summary={schoolData.night_summary}
        />
      </section>

      {existsWork && (
      <section className="p-section" id="section03">
        <div className="c-section-title">
          <span>{schoolData.school_name}の</span>
          <h2>主な就職先</h2>
        </div>

        <section>
          <h3><mark>主な就職先</mark></h3>
          {schoolData.actual_year && (
            <h4>{schoolData.actual_year}卒業生実績</h4>
          )}
          <p>
            {schoolData.work}
          </p>
        </section>
      </section>
      )}

      {appealBlock}

      {interviewLength > 0 && <Interview schoolName={schoolData.school_name} nodes={data.interview.edges} />}

      <section className="p-section" id="section06">
        <div className="c-section-title">
          <span>{schoolData.school_name}の</span>
          <h2>所在地・アクセス</h2>
        </div>

        <div className="cols2">
          {schoolData.map_tag && (
          <div className="c-school-map col">
            <div dangerouslySetInnerHTML={{__html : schoolData.map_tag}} />
            <Link to={mapLinkUrl} target="_blank" className="c-button c-button-school-line">大きい地図で見る</Link>
          </div>
          )}

          <section className="col">
            <h4>所在地</h4>
            <p>〒{schoolData.zip_code} {schoolData.prefecture_name}{schoolData.municipalities}{schoolData.adress}</p>
            {schoolData.access && (
            <React.Fragment>
              <h4>アクセス</h4>
              <p dangerouslySetInnerHTML={{ __html: nl2br(schoolData.access) }} />
            </React.Fragment>
            )}
          </section>
        </div>
      </section>

      <section className="p-section" id="section07">
        <div className="c-section-title">
          <span>{schoolData.school_name}の</span>
          <h2>お問い合わせ</h2>
        </div>

        <div className="cols2">
          {!isSimple && <Contact schoolName={schoolData.school_name} tel={schoolData.tel} website={schoolData.website} />}
          <section className="col">
            {isSimple && (
            <React.Fragment>
              <h4>TEL</h4>
              <p>{schoolData.tel}</p>
            </React.Fragment>
            )}

            {schoolData.fax && (
            <React.Fragment>
              <h4>FAX</h4>
              <p>{schoolData.fax}</p>
            </React.Fragment>
            )}

            {schoolData.email && (
            <React.Fragment>
              <h4>MAIL</h4>
              <p>{schoolData.email}</p>
            </React.Fragment>
            )}

            <Sns twitter={schoolData.twitter} instagram={schoolData.instagram} youtube={schoolData.youtube} facebook={schoolData.facebook} line={schoolData.line} />

            {isSimple && schoolData.website && (
            <React.Fragment>
              <h4>公式WEBサイト</h4>
              <p>
                <Link to={schoolData.website} target="_blank">{schoolData.website}</Link>
              </p>
            </React.Fragment>
            )}
          </section>
        </div>
      </section>

      <div className="c-update">{formatDate(schoolData.updated)} 更新</div>
      <SchoolAreaBlock areaKey={schoolData.area} areaName={area[schoolData.area]} perfCountData={data.prefectures.group} areaSortPrefectures={areaSortPrefectures} />
    </Layout>
  )
}

const Menu = ({isSimple, schoolName, existsWork, existsAppeal, interviewLength}) => {
  if (isSimple) {
    return (
      <ul>
        <li><AnchorLink to="#section02">募集内容・学費</AnchorLink></li>
        {existsWork && <li><AnchorLink to="#section03">主な就職先</AnchorLink></li>}
        <li><AnchorLink to="#section06">所在地・アクセス</AnchorLink></li>
        <li><AnchorLink to="#section07">お問い合わせ</AnchorLink></li>
      </ul>
    )
  } else {
    return (
      <ul>
        <li><AnchorLink to="#section01">{schoolName}に<br className="is-br-pc-show" />ついて</AnchorLink></li>
        <li><AnchorLink to="#section02">募集内容<br className="is-br-pc-show" />・学費</AnchorLink></li>
        {existsWork && <li><AnchorLink to="#section03">主な<br className="is-br-pc-show" />就職先</AnchorLink></li>}
        {existsAppeal && <li><AnchorLink to="#section04">アピール<br className="is-br-pc-show" />ポイント</AnchorLink></li>}
        {interviewLength > 0 && <li><AnchorLink to="#section05">在校生・卒業生<br className="is-br-pc-show" />・先生の声</AnchorLink></li>}
        <li><AnchorLink to="#section06">所在地・<br className="is-br-pc-show" />アクセス</AnchorLink></li>
        <li><AnchorLink to="#section07">お問い合わせ</AnchorLink></li>
      </ul>
    )
  }
}

const Class = ({name, capacity, target, term, expenses, lesson_time, summary}) => {
  if (!capacity && !target && !term && !expenses && !lesson_time) return false
  return (
      <section>
          <h3><mark>{name}</mark></h3>

          <table className="c-table-school-detail">
              {summary && (
              <tr class="overview">
                <th>概要</th>
                <td>
                  <p dangerouslySetInnerHTML={{ __html: nl2br(summary) }} />
                </td>
              </tr>
              )}
              {capacity && (
              <tr>
                  <th>定員</th>
                  <td>{capacity}名</td>
              </tr>
              )}
              {target && (
              <tr>
                  <th>対象</th>
                  <td>{target}</td>
              </tr>
              )}
              {term && (
              <tr>
                  <th>年限</th>
                  <td>{term}年</td>
              </tr>
              )}
              {expenses && (
              <tr>
                  <th>学費</th>
                  <td>
                      初年度納入金<br />
                      {expenses}円<br />
                  </td>
              </tr>
              )}
              {lesson_time && (
              <tr>
                  <th>備考</th>
                  <td>授業時間：{lesson_time}</td>
              </tr>
              )}
          </table>
      </section>
  )
}

const Appeal = ({data}) => {
  const prefix = "appeal"
  const headingKey = "_heading"
  const textKey = "_text"
  const max = 3
  const pointBlocks = []
  for (let i=1; i<=max; i++) {
      const hKey = `${prefix}${i}${headingKey}`
      const tKey = `${prefix}${i}${textKey}`
      if (data[hKey] && data[tKey]) {
          pointBlocks.push((
              <section>
                  <div className="cols2">
                  <div className="col">
                      <img src={withPrefix(`/img/school/${data.school_id}/appeal${i}.jpg`)} style={{width:"100%", height:"100%"}} alt={data.school_name} />
                  </div>
                  <div className="col">
                      <h3><mark>{data[hKey]}</mark></h3>
                      <p dangerouslySetInnerHTML={{ __html: nl2br(data[tKey]) }} />
                  </div>
                  </div>
              </section>
          ))
      }
  }
  if (pointBlocks.length === 0) return false
  return (
    <section className="p-section" id="section04">
        <div className="c-section-title">
        <span>{data.school_name}の</span>
        <h2>アピールポイント</h2>
        </div>
        {pointBlocks}
    </section>
  )
}

const Interview = ({schoolName, nodes}) => {
  return (
    <section className="p-section" id="section05">
      <div className="c-section-title">
        <span>{schoolName}の</span>
        <h2>在校生・卒業生・先生の声</h2>
      </div>

      <div className="cols3">
        {nodes.map((data) => (
          <InterviewCard data={{...data.node.fields, ...data.node.frontmatter}} />
        ))}
      </div>
    </section>
  )
}

const InterviewCard = ({data}) => {
  const { category, slug, basename, title, organization, position, interviewee } = data
  const cardData = {
    voice: {heading: "在校生", color: "yellow"},
    active: {heading: "卒業生", color: "skyblue"},
    future: {heading: "先生", color: "blue"}
  }
  const targetData = cardData[category]
  return (
  <section className="p-school-interview col">
    <h3>{targetData.heading}インタビュー</h3>
    <div className="p-card-interview">
      <Link to={slug} className={`u-bgc-${targetData.color}`}>
        <InterviewTag.CardImage category={category} basename={basename} title={title} />
        <div className="c-card-interview-data">
          <span className="title">{title}</span>
          {InterviewTag.Interviewee({category, organization, position, interviewee})}
        </div>
      </Link>
    </div>
  </section>
  )
}

const Sns = ({twitter="", instagram="", youtube="", facebook="", line=""}) => {
  if (!twitter && !instagram && !youtube && !facebook && !line) return false
  return (
  <React.Fragment>
      <h4>公式SNS</h4>
      <ul className="c-list-sns">
          {twitter && (
          <li>
              <Link to={twitter} target="_blank" className="twitter"><img src={withPrefix("/img/school/btn_twitter@2x.png")} alt="Twitter" /></Link>
          </li>
          )}
          {facebook && (
          <li>
              <Link to={facebook} target="_blank" className="twitter"><img src={withPrefix("/img/school/btn_facebook@2x.png")} alt="Facebook" /></Link>
          </li>
          )}
          {youtube && (
          <li>
              <Link to={youtube} target="_blank" className="youtube" ><img src={withPrefix("/img/school/btn_youtube@2x.png")} alt="YouTube" /></Link>
          </li>
          )}
          {instagram && (
          <li>
              <Link to={instagram} target="_blank" className="instagram"><img src={withPrefix("/img/school/btn_instagram@2x.png")} alt="Instagram" /></Link>
          </li>
          )}
          {line && (
          <li>
              <Link to={line} target="_blank" className="line"><img src={withPrefix("/img/school/btn_line@2x.png")} alt="LINE" /></Link>
          </li>
          )}
      </ul>
  </React.Fragment>
  )
}

const Contact = ({schoolName, tel, website}) => {
  return (
    <div className="col">
      <div className="c-school-tel">
        <a href={`tel:${tel}`}>
          <span>{tel}</span>
          <span>お問い合わせの際に「めざせ！歯科衛生士を見た」とお伝えいただければスムーズです。</span>
        </a>
      </div>

      {website && <Link to={website} target="_blank" className="c-button c-button-school-line">{schoolName}<br className="is-br-sp-show" />公式WEBサイト</Link>}
    </div>
  )
}

const mapLinkUrlCreate = ({map_tag}) => {
  if (!map_tag) return ""
  const match = map_tag.match(/<iframe src="([^"]+)".+<\/iframe>/)
  const iframeSrc = match[1]
  const id = iframeSrc.match(/!1m2!1s(.*?)!/)
  const lon = iframeSrc.match(/!2d(.*?)!/)
  const lat = iframeSrc.match(/!3d(.*?)!/)
  return 'https://www.google.com/maps/place/data=!3m1!4b1!4m5!3m4'
            + '!1s' + id[1]
            + '!8m2!3d' + lat[1]
            + '!4d' + lon[1]
}

const nl2br = (text) => {
  return text.replaceAll(/\r/g, "<br />")
}

export const query = graphql`
  query($slug: String!, $area: String!, $school_name: String!) {
    meta: allMeta(filter: {slug: {eq: "/school/kanto/tokyo/001/"}}) {
      nodes {
        title
        description
        keywords
      }
    }
    school(slug: { eq: $slug }) {
      slug
      order
      area
      prefecture
      updated
      school_id
      school_type
      school_name
      corporate_name
      department
      zip_code
      prefecture_name
      municipalities
      adress
      map_tag
      access
      tel
      fax
      email
      website
      twitter
      instagram
      youtube
      facebook
      line
      actual_year
      work
      daytime
      daytime_target
      daytime_capacity
      daytime_term
      daytime_expenses
      daytime_lesson_time
      night
      night_target
      night_capacity
      night_term
      night_expenses
      night_lesson_time
      list_heading
      top_heading
      top_text
      daytime_summary
      night_summary
      appeal1_heading
      appeal1_text
      appeal2_heading
      appeal2_text
      appeal3_heading
      appeal3_text
    }
    prefectures: allSchool(filter: { area: { eq: $area } }) {
      group(field: prefecture) {
        fieldValue
        totalCount
      }
    }
    interview: allMdx(
      sort: { order: [DESC], fields: [frontmatter___date] }
      filter: {frontmatter: {post_school: {eq: $school_name}}}
    ) {
      edges {
        node {
          fields {
            slug
            category
            basename
          }
          frontmatter {
            date
            title
            organization
            position
            interviewee
          }
        }
      }
    }
  }
`